import consumer from "channels/consumer";
import sha256 from 'crypto-js/sha256';
// var consumer = require("channels");

const hexHash = (msg) => sha256(msg);

document.addEventListener('DOMContentLoaded', function() {
  // button iframe page to interact with customer, show the process
  const busttonContainerId = 'oauth_button';
  let busttonContainer = document.getElementById(busttonContainerId);
  let oauthPopupWindow = null;

  const hideAllButtonOptions = () => {
    const buttonOptionClass = '.button-option';
    document.querySelectorAll(buttonOptionClass).forEach((optionElement) => {
      optionElement.classList.add('d-none');
    });
  }


  if (typeof(busttonContainer) != 'undefined' && busttonContainer != null) {
    let button = busttonContainer.querySelector('a');
    let busttonContainerParent = busttonContainer.parentElement;

    const selectOption = (optionId) => {
      hideAllButtonOptions();
      busttonContainerParent.querySelector(optionId).classList.remove('d-none');
    }

    button.addEventListener('click', (event) => {
      event.preventDefault();

      selectOption('#processing_request');

      // Open an auth window
      oauthPopupWindow = window.open(button.getAttribute("href"));

      // Check if window was closed accidentially
      const checkOauthPopup = setInterval(() => {
        if (!oauthPopupWindow || !oauthPopupWindow.closed) return;
        clearInterval(checkOauthPopup);
        selectOption('#processing_failed');
      }, 500);
    });

    let receiveDecision = (event) => {
      // TODO: change to a real domain
      if (event.origin === window.location.host) {
        console.log(event);
        alert(event.data);
      }
    }

    // Capture customer decision
    window.addEventListener("message", receiveDecision, false);
  };

  // oauth page, receive message from Tink or other 3rd party
  const oauthIframeContainerId = 'add_account';
  let oauthIframeContainer = document.getElementById(oauthIframeContainerId);

  if (typeof(oauthIframeContainer) != 'undefined' && oauthIframeContainer != null) {
    receiveCode = (event) => {
      if (event.origin === 'https://link.tink.com') {
        let data = JSON.parse(event.data);
        let code = data.data; // data.type sould be 'code'
        let tinkIframe = document.querySelector('iframe');

        if (oauthIframeContainer.dataset.redirectUrl != 'undefined' && oauthIframeContainer.dataset.redirectUrl != null) {
          let redirectLink = `${oauthIframeContainer.dataset.redirectUrl}?code=${code}`;
          tinkIframe.src = redirectLink;
        }

        if (oauthIframeContainer.dataset.callbackUrl != 'undefined' && oauthIframeContainer.dataset.callbackUrl != null) {
          let callbackLink = `${oauthIframeContainer.dataset.callbackUrl}?code=${code}`;
          window.location.href = callbackLink
        }
      }
    }

    window.addEventListener("message", receiveCode, false);
  }

  var dnbForm = document.getElementById('dnb');

  if (dnbForm) {
    dnbForm.addEventListener('submit', (event) => {
      event.preventDefault();

      let formData = new FormData(dnbForm);

      fetch('/fast_track/dnb', {
        method: 'POST',
        body: formData,
      })
      .then(respJSON => {
        if (!respJSON.ok) {
          throw Error(respJSON.statusText);
        }

        return respJSON.json();
      })
      .then(resp => {
        if (resp.error_message) {
          throw Error(resp.error_message);
        }

        var newElement = document.createElement("p");
        newElement.textContent = JSON.stringify(resp);
        dnbForm.parentNode.insertBefore(newElement, dnbForm.nextSibling);
      })
      .catch(error => alert(error));
    });
  }

  var debtsForm = document.getElementById('debts');

  if (debtsForm) {
    debtsForm.addEventListener('submit', (event) => {
      event.preventDefault();

      let formData = new FormData(debtsForm);
      // formData.append('tenant_name', tenantName);
      // formData.append('authenticity_token', checkForm.elements['authenticity_token'].value);

      fetch('/fast_track/debts', {
        method: 'POST',
        body: formData,
      })
      .then(respJSON => {
        if (!respJSON.ok) {
          throw Error(respJSON.statusText);
        }

        return respJSON.json();
      })
      .then(resp => {
        if (resp.error_message) {
          throw Error(resp.error_message);
        }

        var newElement = document.createElement("p");
        newElement.textContent = JSON.stringify(resp);
        debtsForm.parentNode.insertBefore(newElement, debtsForm.nextSibling);
      })
      .catch(error => alert(error));
    });
  }

  var debtsFormBankID = document.getElementById('debts_bankid');

  if (debtsFormBankID) {
    debtsFormBankID.addEventListener('submit', (event) => {
      event.preventDefault();

      let formData = new FormData(debtsFormBankID);
      // formData.append('tenant_name', tenantName);
      // formData.append('authenticity_token', checkForm.elements['authenticity_token'].value);

      fetch('/fast_track/debts_bankid', {
        method: 'POST',
        body: formData,
      })
      .then(respJSON => {
        if (!respJSON.ok) {
          throw Error(respJSON.statusText);
        }

        return respJSON.json();
      })
      .then(resp => {
        if (resp.error_message) {
          throw Error(resp.error_message);
        }

        var newElement = document.createElement("p");
        newElement.textContent = JSON.stringify(resp);
        debtsFormBankID.parentNode.insertBefore(newElement, debtsFormBankID.nextSibling);
      })
      .catch(error => alert(error));
    });
  }

  var agreementsForm = document.getElementById('agreements');

  if (agreementsForm) {
    var accountToHuman = (accountText) => {
      return `${accountText.substr(0, 4)}.${accountText.substr(4, 2)}.${accountText.substr(6, 5)}`
    }

    agreementsForm.addEventListener('submit', (event) => {
      event.preventDefault();

      // consumer.subscriptions.create("BankIdChannel", {
      //   received(data) {
      //     alert(JSON.stringify(data));
      //   }
      // });

      let formData = new FormData(agreementsForm);
      console.log(formData);

      // consumer.subscriptions.create({ channel: "BankIdChannel", birth_date: formData.get('debts[birth_date]'), phone_number: formData.get('debts[phone_number]') }, {
      consumer.subscriptions.create({ channel: "BankIdChannel", id: String(hexHash(`${formData.get('debts[birth_date]')}_${formData.get('debts[phone_number]')}`)) }, {
        received(data) {
          // alert(data.body);
          var el = document.createElement('div');
          el.textContent = `Referrece code: ${data.body}`;
          agreementsForm.parentNode.insertBefore(el, agreementsForm.nextSibling);
        }
      });
      // formData.append('tenant_name', tenantName);
      // formData.append('authenticity_token', checkForm.elements['authenticity_token'].value);

      fetch('/fast_track/agreements', {
        method: 'POST',
        body: formData,
      })
      .then(respJSON => {
        if (!respJSON.ok) {
          throw Error(respJSON.statusText);
        }

        return respJSON.json();
      })
      .then(resp => {
        if (resp.error_message) {
          throw Error(resp.error_message);
        }

        console.log(JSON.stringify(resp));

        var newElement = document.createElement("div");
        resp.map(agreement => {
          var el = document.createElement('div');
          el.textContent = `${agreement.corpName} (ID: ${Number(agreement.corpId)}): pay to ${accountToHuman(agreement.corpAccount)} with KID ${agreement.kid} from account ${accountToHuman(agreement.debitAccount)}`
          newElement.appendChild(el);
        })
        if (resp.length == 0) { newElement.textContent = 'No agreement found' }
        // newElement.textContent = JSON.stringify(resp);
        agreementsForm.parentNode.insertBefore(newElement, agreementsForm.nextSibling);
      })
      .catch(error => alert(error));
    });
  }
}, false);

