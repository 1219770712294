import React from "react"
import PropTypes from "prop-types"
var parse = require('html-react-parser');

class BankID extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: 'bankIDSelect',
      errorMessage: null,
      // BankID creds
      fdn: '',
      otp: '',
      password: '',
      phone_number: '',
      birth_date: '',
      // bankidElement: 'fdn',
    };
    this.getSession();
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    let name = target.name;

    this.setState({
      [name]: value,
    });
  }

  setErrorMessage = (error) => {
    this.setState({ errorMessage: error.toString(), referenceCode: null, bankidElement: null });
  }

  redirectPost = (url, data) => {
    var form = document.createElement('form');
    document.body.appendChild(form);
    form.method = 'post';
    form.action = url;
    for (var name in data) {
      var input = document.createElement('input');
      input.type = 'hidden';
      input.name = name;
      input.value = data[name];
      form.appendChild(input);
    }
    form.submit();
  }

  getSession = () => {
    fetch(this.props.bankidSessionUrl, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(respJSON => {
      if (!respJSON.ok) {
        throw Error(respJSON.statusText);
      }
      // console.log(respJSON);

      return respJSON.json();
    })
    .then(resp => {
      if (resp.error_message) {
        throw Error(resp.message);
      }

      this.setState({ sessionId: resp.session });
    })
    .catch(error => this.setErrorMessage(error.toString()));
  }

  restartSession = () => {
    this.setState({ status: 'bankIDSelect', errorMessage: null });
    this.getSession();
  }

  getData = (event) => {
    event.preventDefault();

    let formData = Object.fromEntries(new FormData(event.target));
    let comp = this;

    fetch(this.props.bankdIDUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
              authenticity_token: this.props.authenticityToken,
              session_id: this.state.sessionId,
              user_auth: formData,
            }),
    })
    .then(respJSON => {
      if (!respJSON.ok) {
        throw Error(respJSON.statusText);
      }

      return respJSON.json();
    })
    .then(resp => {
      if (resp.error_message) {
        throw Error(resp.error_message);
      }

      if (resp.bankid_element == 'reference') {
        this.setState({ referenceCode: resp.reference, bankidElement: resp.bankid_element });
        this.checkBankIdStatus();
      } else if (resp.data_id) {
        // this.setState({ status: 'done', data: JSON.stringify(resp.data) });
        this.redirectPost(this.props.callbackUrl, { id: resp.data_id, authenticity_token: this.props.authenticityToken });
      } else {
        this.setState({ bankidElement: resp.bankid_element, bankdIDError: resp.message });
      }
    })
    .catch(error => this.setErrorMessage(error.toString()));
  }

  checkBankIdStatus = () => {
    setTimeout(() => {
      fetch(this.props.bankdIDUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
                authenticity_token: this.props.authenticityToken,
                session_id: this.state.sessionId,
              }),
      })
      .then(respJSON => {
        if (!respJSON.ok) {
          throw Error(respJSON.statusText);
        }

        return respJSON.json();
      })
      .then(resp => {
        if (resp.status == 'error') {
          throw Error(resp.message);
        }

        if (resp.bankid_element == 'reference') {
          this.checkBankIdStatus();
        } else {
          this.setState({ bankidElement: resp.bankid_element });
        }
      })
      .catch(error => this.setErrorMessage(error.toString()));
    }, 1000)
  }

  getDataMobile = (event) => {
    event.preventDefault();

    let formData = Object.fromEntries(new FormData(event.target));
    let comp = this;

    fetch(this.props.bankdIDMobileUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
              authenticity_token: this.props.authenticityToken,
              session_id: this.state.sessionId,
              user_auth: formData,
            }),
    })
    .then(respJSON => {
      if (!respJSON.ok) {
        throw Error(respJSON.statusText);
      }
      // console.log(respJSON);

      return respJSON.json();
    })
    .then(resp => {
      if (resp.status == 'error') {
        throw Error(resp.message);
      }

      // console.log(resp);
      if (resp.reference) {
        comp.setState({ referenceCode: resp.reference });
        this.checkBankIdMobileStatus();
      } else {
        // comp.setState({ status: 'done', data: JSON.stringify(resp.data) });
        this.redirectPost(this.props.callbackUrl, { id: resp.data_id, authenticity_token: this.props.authenticityToken });
      }
    })
    .catch(error => this.setErrorMessage(error.toString()));
    // .catch(error => console.log(parse(error.toString())));
  }

  checkBankIdMobileStatus = () => {
    setTimeout(() => {
      fetch(this.props.bankdIDMobileUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
                authenticity_token: this.props.authenticityToken,
                session_id: this.state.sessionId,
              }),
      })
      .then(respJSON => {
        if (!respJSON.ok) {
          throw Error(respJSON.statusText);
        }

        return respJSON.json();
      })
      .then(resp => {
        if (resp.status == 'error') {
          throw Error(resp.message);
        }

        if (resp.reference) {
          this.checkBankIdMobileStatus();
        } else {
          // this.setState({ status: 'done', data: JSON.stringify(resp.data) });
          this.redirectPost(this.props.callbackUrl, { id: resp.data_id, authenticity_token: this.props.authenticityToken });
        }
      })
      .catch(error => this.setErrorMessage(error.toString()));
    }, 1000)
  }

  renderForm() {
    switch(this.state.status) {
      case 'bankIDSelect': {
        return (
          <React.Fragment>
            <p className="text-center">Select bankID:</p>
            <button className="btn btn-block btn-primary" onClick={ () => this.setState({ status: 'bankID', prevStatus: this.state.status }) } >BankID</button>
            <button className="btn btn-block btn-primary" onClick={ () => this.setState({ status: 'bankIDMobile', prevStatus: this.state.status }) } >BankID på Mobil</button>
          </React.Fragment>
        )

        break;
      }
      case 'bankID': {
        return (
          <React.Fragment>
            <p className="text-center">During the process we collect information from variuos sources:</p>
            <div className="row justify-content-center">
              <div className="col-md-9">
                <ul className="list-unstyled mb-4">
                  <li className="mb-4">
                    <i className="fas fa-check-circle text-primary" /> <span className="lead">Public registers</span>
                    <div>
                      Skatteetaten, Folkeregisteret, Gjeldsregisteret and Brønnøysundregistrene.
                    </div>
                  </li>
                  <li>
                    <i className="fas fa-check-circle text-primary" /> <span className="lead">Primary bank</span>
                    <div>
                      Active agreements: eFaktura and AvtaleGiro.
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            {
              this.state.bankidElement == 'reference' &&
                <div>
                  <p>Reference words: <strong>{ this.state.referenceCode }</strong></p>
                  <p>Follow the instructions on your mobile.</p>
                </div> ||
              this.state.errorMessage &&
                <div>
                  <p>Something went wrong. Please try again.</p>
                  <p>{ parse(this.state.errorMessage) }</p>
                  <button className="btn btn-block btn-primary" onClick={ this.restartSession }>Try again</button>
                </div> ||
              <React.Fragment>
                {
                  this.state.bankdIDError && <div><p>{ this.state.bankdIDError }</p></div>
                }
                {
                  (this.state.bankidElement == 'fdn' || !this.state.bankidElement) &&
                    <form noValidate="novalidate" className="simple_form fdn" acceptCharset="UTF-8" method="POST" onSubmit={ this.getData }>
                      <div className="form-group tel required fdn">
                        <label className="form-control-label tel required" htmlFor="fdn">Personal number <abbr title="required">*</abbr></label>
                        <input className="form-control string tel required" type="tel" autoComplete="off" autoCorrect="off" spellCheck="off" name="fdn" id="data_personal_number" autoFocus onChange={ this.handleInputChange } value={ this.state.fdn } />
                      </div>
                      <input type="submit" name="commit" value="Proceed" className="btn btn-block btn-primary" data-disable-with="Follow instructions..." />
                    </form> ||
                    this.state.bankidElement == 'otp' &&
                      <form noValidate="novalidate" className="simple_form otp" acceptCharset="UTF-8" method="POST" onSubmit={ this.getData }>
                        <div className="form-group tel required opt">
                          <label className="form-control-label tel required" htmlFor="otp">One-time code <abbr title="required">*</abbr></label>
                          <input className="form-control string required" type="password" autoComplete="off" autoCorrect="off" spellCheck="off" name="otp" id="data_personal_number" autoFocus onChange={ this.handleInputChange } value={ this.state.otp } />
                        </div>
                        <input type="submit" name="commit" value="Proceed" id="id123" className="btn btn-block btn-primary" data-disable-with="Send code" disabled={ false } />
                      </form> ||
                    this.state.bankidElement == 'password' &&
                      <form noValidate="novalidate" className="simple_form password" acceptCharset="UTF-8" method="POST" onSubmit={ this.getData }>
                        <div className="form-group tel required password">
                          <label className="form-control-label tel required" htmlFor="password">Peronal password <abbr title="required">*</abbr></label>
                          <input className="form-control string tel required" type="password" autoComplete="off" autoCorrect="off" spellCheck="off" name="password" id="data_personal_number" autoFocus onChange={ this.handleInputChange } value={ this.state.password } />
                        </div>
                        <input type="submit" name="commit" value="Proceed" className="btn btn-block btn-primary" data-disable-with="Finishing up..." />
                      </form>
                }

              </React.Fragment>
            }
          </React.Fragment>
        )

        break;
      }
      case 'bankIDMobile': {
        return (
          <React.Fragment>
            <p className="text-center">During the process we collect information from variuos sources:</p>
            <div className="row justify-content-center">
              <div className="col-md-9">
                <ul className="list-unstyled mb-4">
                  <li className="mb-4">
                    <i className="fas fa-check-circle text-primary" /> <span className="lead">Public registers</span>
                    <div>
                      Skatteetaten, Folkeregisteret, Gjeldsregisteret and Brønnøysundregistrene.
                    </div>
                  </li>
                  <li>
                    <i className="fas fa-check-circle text-primary" /> <span className="lead">Primary bank</span>
                    <div>
                      Active agreements: eFaktura and AvtaleGiro.
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            {
              this.state.referenceCode &&
                <div>
                  <p>Reference words: <strong>{ this.state.referenceCode }</strong></p>
                  <p>Follow the instructions on your mobile.</p>
                </div> ||
                this.state.errorMessage &&
                  <div>
                    <p>Something went wrong. Please try again.</p>
                    <p>{ parse(this.state.errorMessage) }</p>
                    <button className="btn btn-block btn-primary" onClick={ this.restartSession }>Try again</button>
                  </div> ||
                <form noValidate="novalidate" className="simple_form data" acceptCharset="UTF-8" method="POST" onSubmit={ this.getDataMobile }>
                  <div className="form-group tel required data_phone_number">
                    <label className="form-control-label tel required" htmlFor="data_phone_number">Phone number <abbr title="required">*</abbr></label>
                    <input className="form-control string tel required" type="tel" name="phone_number" id="data_phone_number" autoFocus onChange={ this.handleInputChange } value={ this.state.phone_number } />
                  </div>
                  <div className="form-group string required data_birth_date">
                    <label className="form-control-label string required" htmlFor="data_birth_date">Birth date <abbr title="required">*</abbr></label>
                    <input className="form-control string required" type="text" name="birth_date" id="data_birth_date" onChange={ this.handleInputChange } value={ this.state.birth_date } />
                  </div>

                  <input type="submit" name="commit" value="Proceed" className="btn btn-block btn-primary" data-disable-with="Follow instructions..." />
                </form>
            }
          </React.Fragment>
        );
        break;
      }
      case 'done': {
        return (
          <React.Fragment>
            <p className="text-center">Auth successful! Data is collected and available following the request:</p>
            <div className="row justify-content-center">
              <div className="col-md-9">
                <ul className="list-unstyled mb-4">
                  <li className="mb-4">
                    <i className="fas fa-check-circle text-primary" /> <span className="lead">Code</span>
                    <div>
                      <code>
                        { this.state.data }
                      </code>
                    </div>
                  </li>
                  <li>
                    <i className="fas fa-check-circle text-primary" /> <span className="lead">URL to try</span>
                    <div>
                      <code>
                        { this.props.bankdIDUrl }
                      </code>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </React.Fragment>
        )

        break;
      }
    }
  }

  render () {
    return (
      <React.Fragment>
        { this.renderForm() }
      </React.Fragment>
    );
  }
}

BankID.propTypes = {
  bankdIDUrl: PropTypes.string,
  bankdIDMobileUrl: PropTypes.string,
  bankidSessionUrl: PropTypes.string,
  authenticityToken: PropTypes.string,
  callbackUrl: PropTypes.string,
};
export default BankID
